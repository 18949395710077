import { APICore } from "./apiCore";
const api = new APICore();

const makeAdminApi = `/admin/makeAdmin`;  

export async function makeAdmin(email: string) {
    const res = await api.get(`${makeAdminApi}`, {email});
    return res.data;
}

export async function downloadActiveMiners(email: string, days: number) {
    const res = await api.get(`/admin/get-active-user-30-days`, {email, days});
    return res.data;
}

 export async function setAdConfiguration(data: any) {
    const res = await api.create(`/admin/set-ad-configuration`, data);
    return res.data;
}

export async function getAdConfiguration() {
    const res = await api.get(`/get-ad-configuration`, {});
    return res.data;
}