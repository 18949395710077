import axios from "axios";
import { APICore } from "./apiCore";
import { APICore2 } from "./apiCore2";
const api = new APICore();
const api2 = new APICore2();
const totalMinedTokensApi = "/admin/mine/tokens/lifeTime";
const totalShidRewarrdedApi = "/admin/token/shib/totalrewarded";
const totalActiveMinersApi = "/admin/users/activeMiners";
const totalInActiveMinersApi = "/admin/users/inActiveMiners";
const totalUsersApi = "/admin/users/total";
const totalNewACITokensApi = "/admin/newTokensMined";
const totalShibHoldersApi = "/admin/users/holdingShibTokens";
const totalReferralsApi = "/admin/users/totalReferrals";
const totalBannedMinersApi = "/admin/users/totalBannedMiners";

export async function getTotalMinedTokensResponse () {
    const res = await api.get(`${totalMinedTokensApi}`, {});
    return res.data.result.lifeTimeTokens.toFixed(2);
}
export async function getTotalShidRewarrdedResponse () {
    const res = await api.get(`${totalShidRewarrdedApi}`, {});
    return res.data.result.totalShibTokensRewarded.toFixed(2);
}
export async function getTotalActiveMinersResponse () {
    const res = await api.get(`${totalActiveMinersApi}`, {});
    return res.data.result.activeMiners;
}
export async function getTotalInActiveMinersResponse () {
    const res = await api.get(`${totalInActiveMinersApi}`, {});
    return res.data.result.inActiveMiners;
}
export async function getTotalUsersResponse () {
    const res = await api.get(`${totalUsersApi}`, {});
    return res.data.result.totalUsers;
}
export async function getTotalNewACITokensResponse () {
    const res = await api.get(`${totalNewACITokensApi}`, {});
    return res.data.result.newAciTokens.toFixed(2);
}
export async function getTotalShibHoldersResponse () {
    const res = await api.get(`${totalShibHoldersApi}`, {});
    return res.data.result.total;
}
export async function getTotalReferralsResponse () {
    const res = await api.get(`${totalReferralsApi}`, {});
    return res.data.result.totalReferrals;
}
export async function getTotalBannedMinersResponse () {
    const res = await api.get(`${totalBannedMinersApi}`, {});
    return res.data.result;
}
export async function getActiveMinerGraphResponse (range: Number) {
    const res = await api2.get(`/data/activeMinersCount`, {range});
    // const res = await axios.get(`http://localhost:9020/data/activeMinersCount`, {params: {range}});
    // console.log(res.data);
    return res.data;
}

// export async function getDashboardData() {


//     /*
//     const totalMinedTokensResponse = await api.get(`${totalMinedTokensApi}`, {});
//     const totalMinedToken = totalMinedTokensResponse.data.result.lifeTimeTokens.toFixed(2);
//     const totalShidRewarrdedResponse = await api.get(`${totalShidRewarrdedApi}`, {});
//     const totalShidRewarrded = totalShidRewarrdedResponse.data.result.totalShibTokensRewarded.toFixed(2);
//     const totalActiveMinersResponse = await api.get(`${totalActiveMinersApi}`, {});
//     const totalActiveMiners = totalActiveMinersResponse.data.result.activeMiners;
//     const totalInActiveMinersResponse = await api.get(`${totalInActiveMinersApi}`, {});
//     const totalInActiveMiners = totalInActiveMinersResponse.data.result.inActiveMiners;
//     const totalUsersResponse = await api.get(`${totalUsersApi}`, {});
//     const totalUsers = totalUsersResponse.data.result.totalUsers;
//     const totalNewACITokensResponse = await api.get(`${totalNewACITokensApi}`, {});
//     const totalNewACITokens = totalNewACITokensResponse.data.result.newAciTokens.toFixed(2);
//     const totalShibHoldersResponse = await api.get(`${totalShibHoldersApi}`, {});
//     const totalShibHolders = totalShibHoldersResponse.data.result.total;
//     const totalReferralsResponse = await api.get(`${totalReferralsApi}`, {});
//     const totalReferrals = totalReferralsResponse.data.result.totalReferrals;
//     const totalBannedMinersResponse = await api.get(`${totalBannedMinersApi}`, {});
//     const totalBannedMiners = totalBannedMinersResponse.data.result;
    
//     return {
//         totalMinedToken,
//         totalShidRewarrded,
//         totalActiveMiners,
//         totalInActiveMiners,
//         totalUsers,
//         totalNewACITokens,
//         totalShibHolders,
//         totalReferrals,
//         totalBannedMiners
//     }
//         */
//     async function getTotalMinedTokensResponse() {
//         const totalMinedTokensResponse = await api.get(`${totalMinedTokensApi}`, {});
//         return totalMinedTokensResponse.data.result.lifeTimeTokens.toFixed(2);
//     }
//     async function getTotalShidRewarrdedResponse() {
//         const totalShidRewarrdedResponse = await api.get(`${totalShidRewarrdedApi}`, {});
//         return totalShidRewarrdedResponse.data.result.totalShibTokensRewarded.toFixed(2);
//     }
//     async function getTotalActiveMinersResponse() {
//         const totalActiveMinersResponse = await api.get(`${totalActiveMinersApi}`, {});
//         return totalActiveMinersResponse.data.result.activeMiners;
//     }
//     async function getTotalInActiveMinersResponse() {
//         const totalInActiveMinersResponse = await api.get(`${totalInActiveMinersApi}`, {});
//         return totalInActiveMinersResponse.data.result.inActiveMiners;
//     }
//     async function getTotalUsersResponse() {
//         const totalUsersResponse = await api.get(`${totalUsersApi}`, {});
//         return totalUsersResponse.data.result.totalUsers;
//     }
//     async function getTotalNewACITokensResponse() {
//         const totalNewACITokensResponse = await api.get(`${totalNewACITokensApi}`, {});
//         return totalNewACITokensResponse.data.result.newAciTokens.toFixed(2);
//     }
//     async function getTotalShibHoldersResponse() {
//         const totalShibHoldersResponse = await api.get(`${totalShibHoldersApi}`, {});
//         return totalShibHoldersResponse.data.result.total;
//     }
//     async function getTotalReferralsResponse() {
//         const totalReferralsResponse = await api.get(`${totalReferralsApi}`, {});
//         return totalReferralsResponse.data.result.totalReferrals;
//     }
//     async function getTotalBannedMinersResponse() {
//         const totalBannedMinersResponse = await api.get(`${totalBannedMinersApi}`, {});
//         return totalBannedMinersResponse.data.result;
//     }

//     const functionArray = [

// }