import { APICore } from "./apiCore";
import { APICore2 } from "./apiCore2";
const api = new APICore()

export function getUserByEmail(email: string) {
    const baseUrl = "/admin/users/findUser";
    const data = api.get(`${baseUrl}`, { email })
    // console.log(data);
    return data;
}
export function getUserById(userId: string) {
    const baseUrl = "/admin/users/findUserById";
    const data = api.get(`${baseUrl}`, { userId })
    return data;
}

export function getTopMiners() {
    const baseUrl = "/admin/users/topMiners"
    const data = api.get(`${baseUrl}`, {size: 10})
    return data;
}

export function getUserList( link: string, page: number, size: number) {
    if (link === 'topMiners' || link === 'topReferrers') {
        // console.log('topMiners')
        const api2 = new APICore2()
        const baseUrl = `/data/${link}`;
        const data = api2.get(`${baseUrl}`, {page, size})
        return data;
    }
    const baseUrl = `/admin/users/${link}`;
    const data = api.get(`${baseUrl}`, {page, size})
    return data;
}
export function updateTopMiners( params: any ) {
    // const baseUrl = `/update/updateTopMiners`;
    // const api2 = new APICore2()
    // const data = api2.get
    // return data;
    const baseUrl = `/update/updateTopMiners`;
    const api2 = new APICore2()
    const data = api2.get(`${baseUrl}`, params)
    return data;
}
export function updateTopReferrers( params: any ) {
    // const baseUrl = `/update/updateTopReferrers`;
    // const api2 = new APICore2()
    // const data = api2.get(`${base
    // return data;
    const baseUrl = `/update/updateTopReferrers`;
    const api2 = new APICore2()
    const data = api2.get(`${baseUrl}`, params)
    return data;
}
export function updateUser( body: any ) {
    const baseUrl = `/admin/users/udpate`;
    const data = api.updatePatch(`${baseUrl}`, body, null)
    return data
}

export function getRefList(email: string) {
    const baseUrl = `/admin/users/teammatesbyemail`;
    const data = api.get(`${baseUrl}`, { email })
    return data;
}

export function getMemberCount( link: string) {
    const baseUrl = `/admin/${link}`;
    const data = api.get(`${baseUrl}`, {})
    return data;
}

export function deleteUser(email: string) {
    const baseUrl = `/admin/deleteUser`;
    const data = api.create(`${baseUrl}`, [email] )
    return data;
}

export function deleteBulkUsers(emails: string[]) {
    const baseUrl = `/admin/deleteUser`;
    const data = api.create(`${baseUrl}`, emails )
    return data;
}
export function resetPassword(email: string, password: string) {
    // /admin/users/setUserPassword?email=test@orbaic.com&password=Arif123@
    const baseUrl = `/admin/users/setUserPassword`;
    const data = api.create2(`${baseUrl}`, { email, password })
    return data;
}
{/* {{domain}}/api/admin/users/sortby?page=0&size=10000&shibTokens=false&mineTokens=true&referralTokens=false&referralBoostTokens=false&quizRewardTokens=false&createdAt=false&lastLoginAt=false */}
            
export function sortUserList( page: number, size: number, shibTokens: boolean, mineTokens: boolean, referralTokens: boolean, referralBoostTokens: boolean, quizRewardTokens: boolean, createdAt: boolean, lastLoginAt: boolean) {
    const baseUrl = `/admin/users/sortby`;
    const data = api.get(`${baseUrl}`, { page, size, shibTokens, mineTokens, referralTokens, referralBoostTokens, quizRewardTokens, createdAt, lastLoginAt })
    return data;
}

export function enableUser(userId: string) {
    const baseUrl = `/admin/users/enable`;
    const data = api.get(`${baseUrl}`, { userId } )
    return data;
}

export function disableUser(userId: string) {
    const baseUrl = `/admin/users/disable`;
    const data = api.get(`${baseUrl}`, { userId } )
    return data;
}